import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import ProfileHero from "../../components/ProfileHero"
import ImageCopy from "../../components/ImageCopy"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import MemberJobPostings from "../../components/MemberJobPostings"
import MembershipFeaturedStories from "../../components/MemberFeaturedStories"
import { getAll as getAllPostings } from "../../dataAccess/wordPress/jobPostings"
import { getAll as getAllStories } from "../../dataAccess/wordPress/featuredStories"

const maxStoriesByMembership = {
  Entry: 0,
  "Community (Entry)": 0,
  Base: 1,
  "Bronze (Base)": 1,
  Enhanced: 3,
  Premium: 5,
}

const memberStackStories = ["featured-story-what-makes-your-company-a-great-place-for-tech-talent-to-work", "featured-story-what-skills-or-qualities-reflect-applicants-who-would-thrive-in-your-companys-envir", "featured-story-what-initiatives-is-your-company-doing-to-embrace-diversity-and-inclusion", "featured-story-what-is-something-your-company-is-currently-doing-in-emerging-tech"]

const featuredStoryMetadata = {
  "featured-story-what-initiatives-is-your-company-doing-to-embrace-diversity-and-inclusion": {
    title: "Embrace Diversity and Inclusion",
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/header-alt-09.jpg",
    },
  },
  "featured-story-what-is-something-your-company-is-currently-doing-in-emerging-tech": {
    title: "Emerging Tech",
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/header-alt-05.jpg",
    },
  },
  "featured-story-what-makes-your-company-a-great-place-for-tech-talent-to-work": {
    title: "Place for Tech Talent To Work",
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/company-profile-05_hero.jpg",
    },
  },
  "featured-story-what-skills-or-qualities-reflect-applicants-who-would-thrive-in-your-companys-envir": {
    title: "Company's Environment",
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/header-alt-04.jpg",
    },
  },
}

const memberStackStoriesHeaders = [
  {
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/header-alt-09.jpg",
    },
  },
  {
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/header-alt-05.jpg",
    },
  },
  {
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/company-profile-05_hero.jpg",
    },
  },
  {
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/header-alt-04.jpg",
    },
  },
  {
    image: {
      sourceUrl: "https://edit.choosemketech.org/wp-content/uploads/2021/09/header-alt-06.jpg",
    },
  },
]

const mapMemberToStories = (member) => {
  return memberStackStoriesHeaders.map((property) => ({
    content: member[property.content],
    title: member[property.header],
    image: property.image,
  }))
}

const CompanyProfile = ({ data, location, msPerksBenefitsJSONData, msPerksBenefitsStringData, linkedinInfo, facebookInfo, phoneInfo, addressInfo, twitterInfo, companyInfo, jobPostingsInfo, storiesInfo, employeeInfo, totalEmployeeInfo, companyUrlFormat, perksTags, membershipName }) => {
  const [ms, setMemberData] = useState("")
  const [stories, setStories] = useState([])
  const [allPostings, setAllPostings] = useState([])
  const [allStories, setAllStories] = useState([])

  useEffect(() => {
    setAllPostings(data.job.nodes)
    setAllStories(data.story.nodes)
  }, [])

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then(function (member) {
          setMemberData(member)
          //setStories(mapMemberToStories(allStories[item].featuredStories))
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Company Profile",
  })

  if (ms) {
    if (ms["perks-benefits"]) {
      msPerksBenefitsStringData = ms["perks-benefits"]
      try {
        msPerksBenefitsJSONData = JSON.parse(msPerksBenefitsStringData)
      } catch {}
    }
    membershipName = ms.membership.name
  }

  // todo: write this in an array and loop this out.

  if (ms) {
    if (ms.linkedin) {
      linkedinInfo = (
        <li data-ms-content="not-allowed">
          <i className="fab fa-linkedin-in"></i>
          <a href={ms.linkedin} className="link" target="_blank">
            @{ms["company-name"]}
          </a>
        </li>
      )
    }
    if (ms.facebook) {
      facebookInfo = (
        <li data-ms-content="not-allowed">
          <i className="fab fa-facebook-f"></i>
          <a href={ms.facebook} className="link" target="_blank">
            @{ms["company-name"]}
          </a>
        </li>
      )
    }
    if (ms.twitter) {
      twitterInfo = (
        <li data-ms-content="not-allowed">
          <i className="fab fa-twitter"></i>
          <a href={ms.twitter} className="link" target="_blank">
            @{ms["company-name"]}
          </a>
        </li>
      )
    }
    if (ms.address) {
      addressInfo = (
        <li className="address">
          <i className="fas fa-map-marker-alt"></i>{" "}
          <span>
            {ms.address}
            <br /> {ms.city}, {ms.state}
          </span>
        </li>
      )
    }
    if (ms["phone-number"]) {
      phoneInfo = (
        <li className="phone">
          <i className="fas fa-phone-alt"></i> <span>{ms["phone-number"]}</span>
        </li>
      )
    }

    if (ms["company-description"]) {
      companyInfo = ms["company-description"]
    } else {
      companyInfo = 'Look\'s like you didn\'t fill out your company\'s description yet. You can go <a href="/member/company-profile-edit/" class="link">here</a> to edit it.'
    }

    if (allStories) {
      let getStories = []
      for (let item in allStories) {
        if (allStories[item].title === ms.id && (membershipName !== "Entry" || membershipName !== "Community (Entry)")) {
          getStories.push({ content: allStories[item].featuredStories.storyDescription, title: allStories[item].featuredStories.storyHeader, image: { sourceUrl: allStories[item].featuredStories.storyImage } })
          if (allStories[item].featuredStories.storyDescription && allStories[item].featuredStories.storyHeader) {
            storiesInfo = <ImageCopy cards={getStories} />
          }
        }
      }
    }

    for (let item in allPostings) {
      if (allPostings[item].title === ms.id) {
        jobPostingsInfo = (
          <div>
            <h2>Job Openings</h2>
          </div>
        )
      }
    }

    if (ms["total-of-employees"]) {
      employeeInfo = (
        <li>
          <i className="fas fa-users"></i> <span>Employees {ms["total-of-employees"]}</span>
        </li>
      )
    }
    if (ms.industry) {
      totalEmployeeInfo = (
        <li>
          <i className="fas fa-briefcase"></i> <span>Industry Type {ms.industry}</span>
        </li>
      )
    }
    if (ms["company-url"]) {
      companyUrlFormat = ms["company-url"].replace(/(^\w+:|^)\/\//, "")
    }
  }
  if (msPerksBenefitsJSONData && msPerksBenefitsJSONData.length > 0) {
    perksTags = (
      <div className="tags--wrapper">
        <h2>Company Benefits + Perks</h2>
        <div className="tags--container">
          {msPerksBenefitsJSONData?.map((item, index) => {
            return (
              <span className="tag tag--blue tag--blue--perks" key={index}>
                {item.value}
              </span>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <Layout>
      <Seo title="Company Profile" />
      <Breadcrumbs crumbs={crumbs} />
      <ProfileHero profile={ms} />

      <div className="profiles container">
        <div className="row profile__layout">
          <div className="sidebar">
            <ul>
              {addressInfo}
              {phoneInfo}
              {linkedinInfo}
              {facebookInfo}
              {twitterInfo}
              <li>
                <Link to="/contact" className="button button--primary">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/member/company-profile-edit" className="button button--secondary">
                  Edit Profile
                </Link>
              </li>
            </ul>
          </div>

          <div className={`content ${membershipName === "Entry" || membershipName === "Community (Entry)" ? "no-hero" : ""}`}>
            <div className="content__header">
              <h1>Welcome {ms["company-name"]}!</h1>
              <ul>
                {employeeInfo}
                {totalEmployeeInfo}
                <li>
                  <i className="fas fa-external-link-square-alt"></i> <a href={ms["company-url"]}>{companyUrlFormat}</a>
                </li>
              </ul>
            </div>
            <div className="content__description">
              <h2>Company Description</h2>
              <p dangerouslySetInnerHTML={{ __html: companyInfo }} />
              {storiesInfo}
              {perksTags}
              {jobPostingsInfo}
              {ms && <MemberJobPostings allPostings={allPostings} onMemberPostsChanged={() => getAllPostings().then((postings) => setAllPostings(postings))} data={ms} />}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CompanyProfile

export const pageQuery = graphql`
  query {
    story: allWpFeaturedStory {
      nodes {
        id
        title
        modified
        featuredStories {
          storyDescription
          storyHeader
          starttime
          endtime
          storyImage
        }
      }
    }
    job: allWpJobPost {
      nodes {
        jobPostings {
          companyCity
          companyName
          companyState
          companyUrl
          endtime
          expirationDate
          featured
          industry
          jobDescription
          jobTitle
          memberstackId
          roleCategory
          starttime
          techStack
          typeOfPosition
          url
        }
      }
    }
  }
`
