import React from "react"
import ImageDefault from "../assets/images/default-hero-bg.jpg"
import logoDefault from "../assets/images/company-profile-placeholder.png"
import ImageCandidate from "../assets/images/default-hero-bg.jpg"
import logoCandidate from "../assets/images/candidate-profile-placeholder.png"

const ProfileHero = ({ profile, backgroundImage, logoImage, membershipName }) => {
  const logoBgColor = {
    backgroundColor: profile["logo-background-color"],
  }

  const imageBgColor = {
    backgroundColor: profile["background-color"],
  }

  if (profile) {
    membershipName = profile.membership.name

    if (profile.membership.name !== "Candidate") {
      if (profile.logo) {
        logoImage = <img src={profile.logo} alt={profile["company-name"]} />
      } else {
        logoImage = <img src={logoDefault} alt={profile["company-name"]} className="default-logo" />
      }

      if (profile["background-image"]) {
        backgroundImage = <img src={profile["background-image"]} alt={profile["company-name"]} />
      } else {
        backgroundImage = <img src={ImageDefault} alt={profile["company-name"]} />
      }
    } else {
      if (profile.logo) {
        logoImage = <img src={profile.logo} alt={profile["company-name"]} />
      } else {
        logoImage = <img src={logoCandidate} alt={profile["company-name"]} className="default-logo" />
      }

      if (profile["background-image"]) {
        backgroundImage = <img src={profile["background-image"]} alt={profile["company-name"]} />
      } else {
        backgroundImage = <img src={ImageCandidate} alt={profile["company-name"]} />
      }
    }
  }

  return (
    <div className={`profile-hero ${membershipName === "Entry" || membershipName === "Community (Entry)" ? "entry--backgroundImage" : ""}`}>
      <div className="row">
        <div className="profile-hero--banner" style={imageBgColor}>
          {backgroundImage}
        </div>
        <div className="profile-hero--logo" style={logoBgColor}>
          {logoImage}
        </div>
      </div>
    </div>
  )
}

export default ProfileHero
